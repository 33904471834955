<template>
  <v-row>
    <v-col cols="12">
      <v-form ref="formUser">
        <base-card>
          <v-card-title>Registrar Usuarios </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  ref="cliente"
                  v-model="dataUser.cliente"
                  :items="detailClients"
                  item-text="NombreCompania"
                  item-value="IdCompania"
                  label="Compania"
                  prepend-inner-icon="mdi-sitemap"
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  ref="name"
                  v-model="dataUser.name"
                  label="Nombre"
                  prepend-inner-icon="mdi-account-tie"
                  clearable
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  ref="email"
                  v-model="dataUser.email"
                  label="Correo"
                  prepend-inner-icon="mdi-email"
                  clearable
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field
                  ref="password"
                  v-model="dataUser.password"
                  label="Contraseña"
                  prepend-inner-icon="mdi-account-key"
                  clearable
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  ref="rol"
                  v-model="dataUser.rol"
                  :items="typeRol"
                  item-text="textRol"
                  item-value="valueRol"
                  label="Rol"
                  prepend-inner-icon="mdi-account-network"
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-btn
                  class="ma-2"
                  style="background-color:rgb(204 33 40);color:white !important"
                  @click="validateUser"
                >
                  Registrar
                </v-btn>
              </v-col>
              <v-alert
                v-show="successAlert"
                type="success"
                text
              >
                {{ msgSuccessAlert }}
              </v-alert>
            </v-row>
          </v-card-text>
        </base-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
  import { listRoles } from '@/data/listRoles'

  export default {
    name: 'RegistrarUsers',

    data () {
      return {
        dataUser: {},
        rol: '',
        email: '',
        password: '',
        cliente: '',
        name: '',
        typeRol: listRoles,
        errorMessages: '',
        formHasErrors: null,
        successAlert: false,
        msgSuccessAlert: 'Usuario registrado exitosamente',
        detailsClient: {},
        detailsClients: {},
        formRules: [
          (v) => !!v || 'Este campo es requerido',
        ],
      }
    },
    computed: {
      formUser () {
        return {
          cliente: this.dataUser.cliente,
          rol: this.dataUser.rol,
          name: this.dataUser.name,
          password: this.dataUser.password,
          email: this.dataUser.email,
        }
      },
      detailClients () {
        return this.$store.state.clients.listClients.Mensaje
      },
      statusResponseUser () {
        return this.$store.state.users.statusResponseUser
      },
    },
    created () {
      this.getListClients()
    },
    watch:
      {
        formHasErrors () {
          if (this.formHasErrors === false) {
            this.sendUser()
          }
        },
        statusResponseUser () {
          if (this.statusResponseUser === true) {
            this.generateAlertSuccess()
            this.formHasErrors = true
            this.$store.dispatch('users/setStatusResponse', false)
          }
        },
      },
    methods: {
      async getListClients () {
        await this.$store.dispatch('clients/getListClients')
      },
      generateAlertSuccess () {
        this.successAlert = true
        this.$store.dispatch('users/getListUsers')
        this.clearForm()
        setTimeout(() => {
          this.successAlert = false
        }, 3000)
      },
      clearForm () {
        this.$refs.formUser.reset()
      },
      validateUser () {
        this.formHasErrors = false
        Object.keys(this.formUser).forEach((campo) => {
          if (!this.formUser[campo]) this.formHasErrors = true
          this.$refs[campo].validate(true)
        })
      },
      sendUser () {
        this.$store.dispatch('users/insertUser', {
          ...this.dataUser,
        })
      },
    },
  }
</script>
